import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { CartContext } from '../CartContext';
import _ from 'lodash';
import TextInput from '../TextInput';
import Button from '../Button';
import log from 'loglevel';
import OrganicPullout from '../OrganicPullout';
import { useHydrateProducts } from '../hooks';
import { useQueryClient } from '@tanstack/react-query';
import SavingError from '../SavingError';
import {
  PlantType,
  useCreateProductNotificationV1Mutation,
  useSetCartProductQuantityMutation,
} from '../../generated/types-and-hooks';
import config from '../../../config';
import { generateImageCdnPath, ImageSize } from '../../utils/images';
// Primary component used for all /organic-plants/

const ENV_CONFIG = config.get();

const Product = ({ Item }) => {
  const {
    NamePlugtrays,
    BotanicalName,
    CommonName,
    Height,
    Hardiness,
    FlowerColor,
    Characteristics,
    Uses,
    Id,
    TagsPlugtrays,
    DisplayPlugtrays,
    Slug,
    ImageHashes,
  } = Item;
  const queryClient = useQueryClient();
  const productContext = useHydrateProducts({ ProductIds: [Id] });
  const { Cart, ShowWidget } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);

  const quantityChangeMutation = useSetCartProductQuantityMutation({
    onSuccess: (data) => {
      log.debug({ data }, 'quantityChangeMutation: onSuccess');
      queryClient.setQueryData(['cart'], data.SaveCartProduct);
      queryClient.invalidateQueries('checkout');
      ShowWidget();
    },
  });

  const customerNotificationMutation = useCreateProductNotificationV1Mutation({
    onSuccess: (data) => {
      log.debug({ data }, 'successfully saved customer notification');
      setNotifyVisible(false);
    },
  });

  let renderedStock;

  const buildImage = (imageHash, index) => {
    return (
      <div key={imageHash}>
        <img
          src={generateImageCdnPath(imageHash, ImageSize.Medium)}
          alt={`${BotanicalName} ${CommonName || NamePlugtrays} image`}
          itemProp={index === 0 ? 'image' : ''}
        />
      </div>
    );
  };

  const cartQuantity =
    _.get(
      _.filter(Cart.Products, (product) => product.Id === Id),
      [0, 'Quantity']
    ) || 0;
  const addToCart = () => {
    const Quantity = cartQuantity + parseInt(quantity);
    if (typeof Quantity === 'number' && Quantity >= 0) {
      quantityChangeMutation.mutate({ Id, Quantity, PlantType: PlantType.Plugtray });
    } else {
      log.error({ Quantity, product: Item, cartQuantity, quantity }, 'got invalid quantity!');
    }
  };

  const [notifyVisible, setNotifyVisible] = useState(false);
  const [validationVisible] = useState(false);

  let notifyInput;
  const [Email, EmailRendered] = TextInput({
    validationVisible,
    labelText: 'Email address',
    type: 'email',
    required: true,
  });

  if (notifyVisible) {
    const handleSubmit = async (event) => {
      event.preventDefault();
      customerNotificationMutation.mutate({ Id, Email, PlantType: PlantType.Plugtray });
    };

    notifyInput = (
      <form onSubmit={handleSubmit} className='grid-x product--notify-box'>
        <div className='cell small-8 medium-6'>{EmailRendered}</div>
        <div className='cell small-4 medium-4'>
          <Button type='submit' text='Notify me!' isDisabled={customerNotificationMutation.isLoading} />
        </div>
      </form>
    );
  }

  const ResolvedProduct = _.get(productContext, ['data', 0]) || {};

  if (ResolvedProduct.InStockPlugtrays > 0) {
    // Render cart button
    renderedStock = (
      <div className='product--quantity'>
        <link itemProp='availability' href='http://schema.org/InStock' />
        <input
          autoFocus
          type='number'
          defaultValue='1'
          onChange={(e) => setQuantity(e.target.value)}
          data-testid='product-quantity'
          min='1'
          max='360'
        />
        <Button
          style={quantityChangeMutation.isLoading ? 'loading' : undefined}
          isDisabled={quantityChangeMutation.isLoading}
          onClickHandler={addToCart}
          text='Add to cart'
          testId='add-to-cart'
        />
      </div>
    );
  } else {
    renderedStock = (
      <div style={{ maxWidth: '500px' }}>
        {TagsPlugtrays.includes('Lavenders') && (
          <>
            <p>
              <small>
                <em>
                  Lavender plug trays are almost here! They&apos;re usually available late spring through late fall.
                  Sign up to be notified when they&apos;re ready!
                </em>
              </small>
            </p>
          </>
        )}
        <link itemProp='availability' href='http://schema.org/OutOfStock' />
        {!notifyVisible && !customerNotificationMutation.isSuccess && (
          <Button
            style='hollow'
            onClickHandler={() => setNotifyVisible(true)}
            text='Notify me when this plant is back in stock'
          />
        )}
        {notifyInput}
        {customerNotificationMutation.isSuccess && (
          <p className='callout primary'>
            Thank you! You&apos;ll receive an email when we have this item back in stock.
          </p>
        )}
      </div>
    );
  }

  return (
    <div itemType='http://schema.org/Product' className='grid-x grid-margin-x grid-padding-x' itemRef='plugtrays-org'>
      <div className='cell small-10 small-offset-1 hide-for-medium'>
        <div className='product--mobile-header'>
          {BotanicalName ? (
            <h1 className='product--header' itemProp='name'>
              <span className='h4'>{BotanicalName}</span>
              {CommonName}
            </h1>
          ) : (
            <h1 className='product--header' itemProp='name'>
              {NamePlugtrays}
            </h1>
          )}
          {DisplayPlugtrays ? (
            <div itemProp='offers' itemScope itemType='http://schema.org/Offer'>
              <p className='product--price h4'>
                <span itemProp='priceCurrency' content='USD'>
                  $
                </span>
                <meta itemProp='price' content='120.00' />
                {ResolvedProduct.PricePlugtrays} per tray of 128 plants
              </p>
              {renderedStock}
            </div>
          ) : (
            <p>This item is currently not available for purchase.</p>
          )}
        </div>
      </div>
      {_.size(ImageHashes) > 0 && (
        <div className='cell small-12 medium-6 large-4 product--images'>
          <div className='grid-x grid-margin-y'>{_.map(ImageHashes, buildImage)}</div>
        </div>
      )}
      <div
        className={`cell small-12 medium-6 large-8 ${
          _.size(ImageHashes) > 0 ? '' : 'medium-offset-3 large-offset-2'
        } product--details`}
      >
        <span className='show-for-medium'>
          {BotanicalName ? (
            <h1 className='product--header' itemProp='name'>
              <span className='h4'>{BotanicalName}</span>
              {CommonName}
            </h1>
          ) : (
            <h1 className='product--header' itemProp='name'>
              {NamePlugtrays}
            </h1>
          )}
          {DisplayPlugtrays ? (
            <div itemProp='offers' itemScope itemType='http://schema.org/Offer'>
              <p className='product--price h4'>
                <span itemProp='priceCurrency' content='USD'>
                  $
                </span>
                <meta itemProp='price' content={ResolvedProduct.PricePlugtrays} />
                {ResolvedProduct.PricePlugtrays}
                <span className='product--price-description'> per tray of 128 plants</span>
              </p>
              {renderedStock}
              <SavingError error={quantityChangeMutation.error} />
              <SavingError error={customerNotificationMutation.error} />
            </div>
          ) : (
            <p>This item is currently not available for purchase.</p>
          )}
        </span>
        <h3>About this plant</h3>
        <dl itemProp='description'>
          {Height && (
            <div itemProp='additionalProperty' itemScope itemType='http://schema.org/PropertyValue'>
              <dt className='header__small-caps' itemProp='name'>
                Height
              </dt>
              <dd itemProp='value'>{Height}</dd>
            </div>
          )}
          {Hardiness && (
            <div itemProp='additionalProperty' itemScope itemType='http://schema.org/PropertyValue'>
              <dt className='header__small-caps' itemProp='name'>
                Hardiness
              </dt>
              <dd itemProp='value'>{Hardiness}</dd>
            </div>
          )}
          {FlowerColor && (
            <div itemProp='additionalProperty' itemScope itemType='http://schema.org/PropertyValue'>
              <dt className='header__small-caps' itemProp='name'>
                Flower Color
              </dt>
              <dd itemProp='value'>{FlowerColor}</dd>
            </div>
          )}
          {Characteristics && (
            <div itemProp='additionalProperty' itemScope itemType='http://schema.org/PropertyValue'>
              <dt className='header__small-caps' itemProp='name'>
                Characteristics
              </dt>
              <dd itemProp='value'>{Characteristics}</dd>
            </div>
          )}
          {Uses && (
            <div itemProp='additionalProperty' itemScope itemType='http://schema.org/PropertyValue'>
              <dt className='header__small-caps' itemProp='name'>
                Uses
              </dt>
              <dd itemProp='value'>{Uses}</dd>
            </div>
          )}
        </dl>
        <p>
          <a href={`https://mountainvalleygrowers.com/organic-plants/${Slug}`}>
            Read more about this plant on Mountain Valley Growers »
          </a>
        </p>
        <OrganicPullout />
      </div>
    </div>
  );
};

Product.defaultProps = {
  Item: undefined,
};

Product.propTypes = {
  Item: PropTypes.object,
};

Product.displayName = 'ProductComponent';

export default Product;
