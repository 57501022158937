import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/Header';
import Product from '../components/products/Product';

const ProductTemplate = ({ pageContext }) => {
  const { NamePlugtrays, BotanicalName, CommonName, Uses, Characteristics } = pageContext;
  return (
    <Layout>
      <SEO
        title={`Organic ${NamePlugtrays}s from Mountain Valley Growers`}
        keywords={[BotanicalName, CommonName, Uses, Characteristics]}
        description={`Shop certified organic ${NamePlugtrays}s at Mountain Valley Growers`}
      />
      <Header />
      <div className='grid-x grid-margin-x'>
        <Product Item={pageContext} />
      </div>
    </Layout>
  );
};

ProductTemplate.displayName = 'ProductTemplate';

ProductTemplate.defaultProps = {
  pageContext: undefined,
};

ProductTemplate.propTypes = {
  pageContext: PropTypes.object,
};

export default ProductTemplate;
